'use client'

import Modal from 'react-bootstrap/Modal'
import styles from './lib/styles.module.scss'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {Icons} from '@/styles/icons/icons'
import * as Lib from './lib'
import UI_Button from '@/components/ui-kit/button/Button'
import clsx from 'clsx'
import {useTranslate} from '@/hooks/useTranslate'
import * as Sentry from '@sentry/nextjs'
import {useGlobalStore} from '@/store'

export const UserSettingModal = ({router}: {router: 'appRouter' | 'pagesRouter'}) => {
  const {get, on} = Lib.useUserSettingModal({router})
  const t = useTranslate
  const {userSetting} = useGlobalStore((state) => ({
    userSetting: state.userSetting,
  }))

  const updateUserSettingHandler = (countryId: number, language: string) => {
    if (!countryId || !language) {
      Sentry.captureException(new Error('countryId and language are required.'))
      return
    }

    const country = get.countries?.find((c) => c.id === countryId)
    if (!country) {
      Sentry.captureException(new Error('invalid country selected in user settings modal.'))
      return
    }

    on.setTemporarySelectedCountry({
      language: language,
      country_id: countryId,
      country_name: country.name,
      currencyName: country.currency_unit,
      countryFlagUrl: country.flag_url,
      phoneCode: +country.phone_code,
      iso3: country.iso3,
      getUserSettingLoading: false,
    })
  }

  return (
    <Modal
      show={get.show}
      onHide={on.handleToggleModal}
      centered={!get.isMobile}
      dialogClassName={styles['modal_dialog']}
      className={styles['modal']}
      backdropClassName={styles['modal_backdrop']}
      contentClassName={
        get.show ? clsx(styles['mobile_user_setting_modal_content']) : styles['slide-down']
      }
    >
      <main className={styles['container']}>
        <header className={styles['header']}>
          <UI_Typography variant={get.isMobile ? 'Medium/Med14' : 'Medium/Med20'}>
            {t('SELECT_COUNTRY_AND_LANGUAGE')}
          </UI_Typography>
          <Icons.Close className={styles['close_icon']} onClick={on.handleToggleModal} />
        </header>
        <hr className={styles['hr']} />

        <section className={styles['content']}>
          {get.countries?.map((country) => (
            <Lib.C.SettingItem
              {...country}
              key={country.id}
              onSelect={(language: string) => {
                updateUserSettingHandler(country.id, language)
              }}
              selectedCountryId={
                get.temporarySelectedCountry
                  ? get.temporarySelectedCountry.country_id
                  : userSetting.country_id
              }
              selecteLanguage={
                get.temporarySelectedCountry?.language
                  ? get.temporarySelectedCountry.language
                  : userSetting.language
              }
            />
          ))}
        </section>
        <div className={styles['save_button']}>
          <UI_Button variant='primary' onClick={on.handleSaveUserSettings}>
            {t('APPROVE_AND_CONTINUE')}
          </UI_Button>
        </div>
      </main>
    </Modal>
  )
}
