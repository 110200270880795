type IconProps = React.HTMLAttributes<SVGElement>

export const Icons = {
  Close: (props: IconProps) => (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.8594 2.89844L8.75781 7L12.8594 11.1406C13.3672 11.6094 13.3672 12.4297 12.8594 12.8984C12.3906 13.4062 11.5703 13.4062 11.1016 12.8984L7 8.79688L2.85938 12.8984C2.39062 13.4062 1.57031 13.4062 1.10156 12.8984C0.59375 12.4297 0.59375 11.6094 1.10156 11.1406L5.20312 7L1.10156 2.89844C0.59375 2.42969 0.59375 1.60938 1.10156 1.14062C1.57031 0.632812 2.39062 0.632812 2.85938 1.14062L7 5.24219L11.1016 1.14062C11.5703 0.632812 12.3906 0.632812 12.8594 1.14062C13.3672 1.60938 13.3672 2.42969 12.8594 2.89844Z'
        fill='#666666'
      />
    </svg>
  ),
}
