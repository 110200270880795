'use client'

import {UserSettingModal} from '@/components/user-setting-modal/UserSettingModal'
import {getCountryByCountryId} from '@/i18n'
import * as Lib from './lib'

const UserSettings = () => {
  const {get, on} = Lib.H.useUserSettings()

  return (
    <div style={{flex: '0.1'}}>
      {get.isClient && !get.getUserSettingLoading ? (
        <>
          <Lib.C.HeaderSelectButton
            handleClick={on.handleClick}
            currentCountryISO2={getCountryByCountryId(
              get.currentCountryId.toString()
            )?.iso2.toUpperCase()}
          />

          <UserSettingModal router='appRouter' />
        </>
      ) : (
        <Lib.C.PlaceHolder />
      )}
    </div>
  )
}

export default UserSettings
