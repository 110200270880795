'use client'

import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'
import {RectShape, TextBlock} from 'react-placeholder/lib/placeholders'
import styles from './styles.module.scss'

const shapes = [
  {
    width: '21%',
  },
  {
    width: '12%',
  },
  {
    width: '8%',
  },
  {
    width: '10%',
  },
  {
    width: '18%',
  },
  {
    width: '15%',
  },
  {
    width: '16%',
  },
  {
    width: '12%',
  },
  {
    width: '20%',
  },
]

export default function PlaceHolder() {
  const isMobile = useCustomMediaQuery(578)
  return (
    <div className={styles['placeholder']}>
      {shapes.slice(0, isMobile ? 5 : 12).map((shape, index) => (
        <RectShape
          color='#E6E6E6'
          key={shape.width + index}
          style={{borderRadius: '17.5px', width: shape.width, height: '13px'}}
        />
      ))}
    </div>
  )
}
