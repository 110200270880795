'use client'

import clsx from 'clsx'
import {usePathname} from 'next/navigation'
import {useEffect, useMemo, useState} from 'react'
import {useGlobalStore} from '@/store'
import {useShallow} from 'zustand/react/shallow'

// components
import CartLink from '../cart-link'
import FavoriteLink from '../favorite-link'
import Logo from '../logo'
import MobileSidebar from '../mobile-sidebar'
import UserProfileLink from '../user-profile-link'
import Menu from './menu'
import MenuButton from './menu-button'
import Search from './search-button'

// styles
import {MenuItem} from '@/services/menus.service'
import styles from './styles.module.scss'

const MobileHeader = ({
  primaryMenu,
  secondaryMenu,
}: {
  primaryMenu: MenuItem[]
  secondaryMenu: MenuItem[]
}) => {
  const pathname = usePathname()
  const {mobileSidebarIsOpen, handleUpdateMobileSidebarIsOpen} = useGlobalStore(
    useShallow((state) => ({
      mobileSidebarIsOpen: state.mobileSidebarIsOpen,
      handleUpdateMobileSidebarIsOpen: state.handleUpdateMobileSidebarIsOpen,
    }))
  )

  // states
  const [enableMenuSearchMode, setEnableMenuSearchMode] = useState(false)
  const [shouldCategoriesFixedPosition, setShouldCategoriesFixedPosition] = useState(false)
  const [shouldMainHeaderFixedPosition, setShouldMainHeaderFixedPosition] = useState(false)

  const showCategories = useMemo(() => {
    return pathname === '/home'
  }, [pathname])

  const showMainHeader = useMemo(() => {
    return pathname!.includes('/categories/') || pathname!.includes('/products/')
  }, [pathname])

  const toggleVisibility = () => {
    if (window.pageYOffset > 145) {
      if (showCategories) {
        setShouldCategoriesFixedPosition(true)
      } else if (showMainHeader) {
        setShouldMainHeaderFixedPosition(true)
      }
    } else {
      setShouldCategoriesFixedPosition(false)
      setShouldMainHeaderFixedPosition(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [showCategories, showMainHeader])

  return (
    <div className={styles['mobile-header']}>
      <div className={styles['main-header']} data-testid='main-mobile-header'>
        <div className={styles['main-header__left']}>
          <MenuButton onClick={() => handleUpdateMobileSidebarIsOpen(true)} />
          <Logo width={59} height={26} />
        </div>

        <div className={styles['main-header__right']}>
          <UserProfileLink isMobile={true} />
          <FavoriteLink isMobile={true} />
          <CartLink isMobile={true} />
        </div>
      </div>

      <div
        className={styles['main-header__search-container']}
        data-testid='header-mobile-search-button'
      >
        <Search
          onClick={() => {
            handleUpdateMobileSidebarIsOpen(true)
            setEnableMenuSearchMode(true)
          }}
        />
      </div>

      <Menu items={primaryMenu} />

      <div
        className={clsx(styles['main-header__categories__fixed-position'], {
          [styles['main-header__categories__fixed-position__visible']]:
            shouldCategoriesFixedPosition,
        })}
      >
        <Menu items={primaryMenu} />
      </div>

      <div
        className={clsx(styles['main-header__fixed-position'], {
          [styles['main-header__fixed-position__visible']]: shouldMainHeaderFixedPosition,
        })}
      >
        <div className={styles['main-header']}>
          <div className={styles['main-header__left']}>
            <MenuButton onClick={() => handleUpdateMobileSidebarIsOpen(true)} />
            <Logo width={59} height={26} />
          </div>

          <div className={styles['main-header__right']}>
            <UserProfileLink isMobile={true} />
            <FavoriteLink isMobile={true} />
            <CartLink isMobile={true} />
          </div>
        </div>
      </div>

      <MobileSidebar
        isOpen={mobileSidebarIsOpen}
        searchMode={enableMenuSearchMode}
        handleClose={() => {
          handleUpdateMobileSidebarIsOpen(false)
          setEnableMenuSearchMode(false)
        }}
        primaryMenu={primaryMenu}
        secondaryMenu={secondaryMenu}
      />
    </div>
  )
}

export default MobileHeader
