import * as Lib from '../_lib'

export const priceItems = [
  {
    min: '0',
    max: '150',
  },
  {
    min: '150',
    max: '250',
  },
  {
    min: '250',
    max: '400',
  },
  {
    min: '400',
    max: '700',
  },
  {
    min: '700',
    max: '1750',
  },
  {
    min: '1750',
    max: '175000',
  },
]

export const idsInitialData: Record<Lib.T.SearchQueries, number[] | string> = {
  brand_id: [],
  sizes: [],
  category_id: [],
  sort: '',
  min_price: '',
  max_price: '',
  color_id: [],
  fabric_id: [],
  rating: '',
  height_id: [],
  is_new: '',
  with_photo: '',
}

export const idsMobileInitialData: Record<Lib.T.SearchQueries, {name: string; id: number}[]> = {
  brand_id: [{id: 0, name: ''}],
  sizes: [{id: 0, name: ''}],
  category_id: [{id: 0, name: ''}],
  color_id: [{id: 0, name: ''}],
  fabric_id: [{id: 0, name: ''}],
  height_id: [{id: 0, name: ''}],
  sort: [],
  min_price: [],
  max_price: [],
  rating: [],
  is_new: [],
  with_photo: [],
}

export const PER_PAGE = 20
export const lastSearchStateKey = 'LAST_SEARCH_STATE'
export const lastPageStateKey = 'PAGE_STATE'
export const lastSearchScrollStateKey = 'LAST_SEARCH_SCROLL_STATE'
export const lastNavigatedUrlKey = 'LAST_NAVIGATED_URL'
