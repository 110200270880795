'use client'

import {useTranslations} from 'next-intl'
import React, {useEffect, useState} from 'react'
import toast from 'react-hot-toast'
import {QueryClient, QueryCache, QueryClientProvider} from 'react-query'

export const QueryClientComponent = ({children}: {children: React.ReactNode}) => {
  const t = useTranslations()

  const [errorCode, setErrorCode] = useState()

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (error: any) => {
        if (error?.response?.status === 404 || error?.response?.status === 403) return
        if (error.response.status === 500) {
          toast.error(t('SOMETHING_WENT_WRONG'))
          return
        }
        setErrorCode(error.response?.status)
      },
    }),
    defaultOptions: {
      queries: {
        retry: (failureCount, error: any) => {
          if (error?.response?.status >= 500 && error?.response?.status <= 599) {
            if (failureCount >= 1) return false
            return true
          }
          return false
        },
        retryDelay: 3000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  })

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
