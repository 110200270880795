import clsx from 'clsx'
import React, {InputHTMLAttributes} from 'react'
import styles from './styles.module.scss'

interface UI_Checkbox extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  type?: React.HTMLInputTypeAttribute | undefined
}

const UI_Checkbox = (props: UI_Checkbox) => {
  const {id, type = 'checkbox', ...rest} = props
  return (
    <div className='d-flex gap-2 align-items-center'>
      <input className={clsx('form-check-input', styles.checkbox)} type={type} id={id} {...rest} />
    </div>
  )
}

export default UI_Checkbox
