'use client'

import * as Lib from './'
import {useLocale, useTranslations} from 'next-intl'
import {useEffect, useState} from 'react'
import {useShallow} from 'zustand/react/shallow'
import {useGlobalStore} from '@/store'
import {handleCheckAuthStatus} from '@/utils/authHelper'

export const useUserSettings = () => {
  const {userSetting, handleToggleUserSettingModal} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      handleToggleUserSettingModal: state.handleToggleUserSettingModal,
    }))
  )

  const t = useTranslations()
  const locale = useLocale()
  const [isClient, setIsClient] = useState(false)

  const {isLogin} = handleCheckAuthStatus()

  const currentLanguage = Lib.Const.languages.find((x) => x.lang === locale.split('-')[0])

  const handleClick = () => {
    handleToggleUserSettingModal(!userSetting.showUserSettingModal)
  }

  useEffect(() => {
    setIsClient(true)
  }, [])

  return {
    get: {
      showUserSettingModal: userSetting.showUserSettingModal,
      t,
      lang: locale.split('-')[0],
      isLogin,
      currentLanguage,
      currentCurrency: userSetting?.currencyName,
      currentCountryId: userSetting?.country_id,
      getUserSettingLoading: userSetting?.getUserSettingLoading,
      isClient,
    },
    on: {
      handleClick,
    },
  }
}
