'use client'

import {useParams} from 'next/navigation'
import CartLink from '../../cart-link'
import FavoriteLink from '../../favorite-link'

// components
import Logo from '../../logo'
import UserProfileLink from '../../user-profile-link'
import Search from './search'

// styles
import styles from './styles.module.scss'

export const CART_LINK = '/cart'
export const FAVORITE_LINK = '/favorite'

const MainHeader = () => {
  const params = useParams<{locale: string}>()

  return (
    <>
      <div className={styles['main-header']}>
        {/* logo */}
        <div className={styles['main-header__logo']}>
          <Logo width={118} height={52} />
        </div>

        {/* search */}
        <div className={styles['main-header__search-container']}>
          <Search />
        </div>

        {/* actions */}
        <div className={styles['main-header__links']}>
          <UserProfileLink />
          <FavoriteLink />
          <CartLink />
        </div>
      </div>
    </>
  )
}

export default MainHeader
