import {createTheme} from '@mui/material'
import 'typeface-open-sans'

const white = '#FFFFFF'

export const theme = createTheme({
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    primary: {
      main: '#F26B26',
      variant: '#E26C2D',
      background: '#F8F8F8',
      surface: 'rgba(255, 255, 255, 0.87)',
      onSurfaceGray: '#E1E1E1',
      onBackground: '#323232',
    },
    error: {
      main: '#E00930',
      light: '#E00930',
      dark: '#C10628',
    },
    success: {
      main: '#8DCD03',
      light: '#8DCD03',
      dark: '#5D9405',
    },
    grey: {
      main: '#E1E1E1',
      light: '#F2F2F2',
      outline: '#EBEBEB',
      background: '#F9F9F9',
      surface: '#e9e9e9',
      divider: '#747474',
      newDark: '#a9a9a9',
    },
    text: {
      primary: '#3D3D3D',
      secondary: '#777777',
      disabled: '#BDBDBD',
    },
    customeColor: {
      commonBackground: white,
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontweightHalfBold: 600,
    fontWeightBold: 700,
    tofisaRegular1: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.125rem',
    },

    tHeadlineRegular: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: '1.563rem',
    },
    tHeadlineSemiBold: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '1.313rem',
      lineHeight: '1.813rem',
    },
    tHeadlineBold: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '1.75rem',
      lineHeight: '2.375rem',
    },
    tBodyRegular: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.125rem',
    },
    tBodySemiBold: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '18px',
    },
    tBodyBold: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 700,
      fontSize: '1rem',
      lineHeight: '100%',
    },
    tBodyMedium: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '0.938rem',
      lineHeight: '100%',
    },
    tCaptionRegular: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    tCaptionSemiBold: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    tCaptionUnderline: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    tCaptionRegular2: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.188rem',
    },
    tCaptionSemiBold2: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1.188rem',
    },
    tCaptionUnderline2: {
      fontFamily: 'Open Sans',
      fontStyle: 'Normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.188rem',
    },
  },
})
