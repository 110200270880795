'use client'

import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'
import UI_Checkbox from '@/components/ui-kit/checkbox/Checkbox'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {useTranslate} from '@/hooks/useTranslate'
import {RenderIf} from '@/utils'
import clsx from 'clsx'
import Image from 'next/image'
import styles from './styles.module.scss'

type Props = {
  id: number
  name: string
  flag_url: string
  languages: string[]
  currency_unit: string
  iso3: string
  phone_code: string
  onSelect: (language: string) => void
  selectedCountryId: number
  selecteLanguage: string
}

export const SettingItem = ({
  id,
  name,
  flag_url,
  languages,
  onSelect,
  selectedCountryId,
  selecteLanguage,
}: Props) => {
  const isMobile = useCustomMediaQuery(576)

  const handleOnChangeLanguage = (language?: string) => {
    onSelect(language ?? languages[0])
  }

  return (
    <div
      className={clsx(
        styles['container'],
        selectedCountryId === id && styles['selected_container']
      )}
      onClick={() => handleOnChangeLanguage()}
    >
      <div className={styles['country']}>
        <Image src={flag_url} alt={name} width={28} height={28} objectFit='contain' />
        <UI_Typography variant={isMobile ? 'Medium/Med12' : 'Medium/Med14'}>{name}</UI_Typography>
      </div>
      <RenderIf isTrue={selectedCountryId === id}>
        <div className={styles['languages']}>
          {languages.map((language) => (
            <div className={styles['language']}>
              <UI_Checkbox
                id={id.toString() + language}
                type='radio'
                checked={language === selecteLanguage && selectedCountryId === id}
                onChange={() => handleOnChangeLanguage(language)}
              />
              <UI_Typography
                htmlFor={id.toString() + language}
                component='label'
                variant={isMobile ? 'Regular/Reg12' : 'Regular/Reg14'}
              >
                {useTranslate(language)}
              </UI_Typography>
            </div>
          ))}
        </div>
      </RenderIf>
    </div>
  )
}
