'use client'

import {useSearchParams} from 'next/navigation'
import * as LIB from './_lib'

export default ({primaryMenu, secondaryMenu}: {primaryMenu: any; secondaryMenu: any}) => {
  const searchParams = useSearchParams()
  const viewPort = searchParams?.get('viewport')
  const isMobile = viewPort === 'mobile'

  return isMobile ? (
    <LIB.C.HeaderMobile primaryMenu={primaryMenu} secondaryMenu={secondaryMenu} />
  ) : (
    <LIB.C.HeaderDesktop categories={primaryMenu} />
  )
}
