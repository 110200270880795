import {
  lastPageStateKey,
  lastSearchScrollStateKey,
  lastSearchStateKey,
} from '@/app/[locale]/(main-layout)/categories/[cid]/_lib/constants'

export const clearLastVisitedProductsSession = () => {
  sessionStorage.removeItem(lastSearchStateKey)
  sessionStorage.removeItem(lastSearchScrollStateKey)
  sessionStorage.removeItem(lastPageStateKey)
}
