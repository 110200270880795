import clsx from 'clsx'

// components
import PlaceHolder from '../../placeholder'
import Link from 'next/link'
import ReactPlaceholder from 'react-placeholder/lib'

// styles
import styles from './../styles.module.scss'
import {MenuItem} from '@/services/menus.service'
import {
  lastSearchScrollStateKey,
  lastSearchStateKey,
} from '@/app/[locale]/(main-layout)/categories/[cid]/_lib/constants'
import {clearLastVisitedProductsSession} from '@/utils/LastVisitedProducts'

const Menu = ({items}: {items: MenuItem[]}) => {
  return (
    <div
      className={clsx(styles['main-header__categories'])}
      onClick={clearLastVisitedProductsSession}
    >
      <ReactPlaceholder ready={!!items.length} customPlaceholder={<PlaceHolder />}>
        {items.map((item: any) => {
          return (
            <Link
              key={item.id}
              href={item.link}
              className={clsx(styles['main-header__categories-link'])}
              data-testid='header-category-item'
            >
              {item.name}
            </Link>
          )
        })}
      </ReactPlaceholder>
    </div>
  )
}

export default Menu
