import {UserSettings} from '@/types/UserSettings'
import * as Lib from './lib'

export class UserSettingsService {
  private apiCaller

  endpoint = '/user-setting'
  anonymousRoute = '/anonymous/user-setting'
  currencyRoute = '/currencies/'
  countryRoute = '/countries/deliverable'

  constructor(token: string, {country_id}: Lib.T.ApiCaller.DefaultParams) {
    this.apiCaller = Lib.Helper.ApiCaller({
      isSearchService: false,
      accessToken: <string>token!,
      defaultParams: {
        country_id: country_id ? country_id : Number(process.env.NEXT_PUBLIC_DEFAULT_COUNTRY),
      },
    })
  }

  async getCurrenciesList(): Promise<
    Lib.T.ApiCaller.Response<Lib.T.UserSettings.CurrenciesListResponse>
  > {
    return await this.apiCaller.get(this.currencyRoute)
  }

  async getCountriesList(): Promise<
    Lib.T.ApiCaller.Response<Lib.T.UserSettings.CountriesListResponse>
  > {
    return await this.apiCaller.get(this.countryRoute)
  }

  async getUserSettings(
    isLogin: boolean,
    uuid: string | null | undefined
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.UserSettings.UserSettingsResponse>> {
    if (isLogin) {
      return await this.apiCaller.get(this.endpoint)
    } else {
      return await this.apiCaller.get(this.anonymousRoute, {
        headers: {
          ...(uuid && {UUID: uuid}),
        },
      })
    }
  }

  async addToUserSettings(
    data: UserSettings
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.UserSettings.AddToUserSettingsResponse>> {
    return await this.apiCaller.post(this.endpoint, data)
  }

  async addToUserSettingsAnonymous(
    data: UserSettings,
    uuid?: string
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.UserSettings.AddToAnonymousUserSettingsResponse>> {
    if (uuid) {
      return await this.apiCaller.post(this.anonymousRoute, data, {
        headers: {
          UUID: uuid,
        },
      })
    }
    return await this.apiCaller.post(this.anonymousRoute, data)
  }

  async getUserDetail(): Promise<Lib.T.ApiCaller.Response<Lib.T.Auth.AuthResult>> {
    return await this.apiCaller.get('/detail')
  }

  async updateUserDetail(
    body: Lib.T.UserSettings.UpdateUserDetailRequestBody
  ): Promise<Lib.T.ApiCaller.Response<Lib.T.UserSettings.UpdateUserDetailResponse>> {
    return await this.apiCaller.patch('/profile', body)
  }
}
