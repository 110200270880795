export const Close = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6875 11.3125C13.0938 11.6875 13.0938 12.3438 12.6875 12.7188C12.5 12.9062 12.25 13 12 13C11.7188 13 11.4688 12.9062 11.2812 12.7188L8 9.4375L4.6875 12.7188C4.5 12.9062 4.25 13 4 13C3.71875 13 3.46875 12.9062 3.28125 12.7188C2.875 12.3438 2.875 11.6875 3.28125 11.3125L6.5625 8L3.28125 4.71875C2.875 4.34375 2.875 3.6875 3.28125 3.3125C3.65625 2.90625 4.3125 2.90625 4.6875 3.3125L8 6.59375L11.2812 3.3125C11.6562 2.90625 12.3125 2.90625 12.6875 3.3125C13.0938 3.6875 13.0938 4.34375 12.6875 4.71875L9.40625 8.03125L12.6875 11.3125Z'
      fill='#ABB5BE'
    />
  </svg>
)

export const Search = ({fill = '#ABB5BE'}: {fill?: string}) => (
  <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.7188 10.9062C13.0703 11.2812 13.0703 11.8672 12.7188 12.2422C12.3438 12.5938 11.7578 12.5938 11.3828 12.2422L8.59375 9.42969C7.63281 10.0625 6.46094 10.3906 5.19531 10.2266C3.03906 9.92188 1.30469 8.16406 1.02344 6.03125C0.625 2.84375 3.32031 0.148438 6.50781 0.546875C8.64062 0.828125 10.3984 2.5625 10.7031 4.71875C10.8672 5.98438 10.5391 7.15625 9.90625 8.09375L12.7188 10.9062ZM2.85156 5.375C2.85156 7.03906 4.1875 8.375 5.85156 8.375C7.49219 8.375 8.85156 7.03906 8.85156 5.375C8.85156 3.73438 7.49219 2.375 5.85156 2.375C4.1875 2.375 2.85156 3.73438 2.85156 5.375Z'
      fill={fill}
    />
  </svg>
)

export const SignOut = () => (
  <svg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.875 9.625C0.820312 9.625 0 8.78516 0 7.75V2.75C0 1.71484 0.820312 0.875 1.875 0.875H3.125C3.45703 0.875 3.75 1.16797 3.75 1.5C3.75 1.85156 3.45703 2.125 3.125 2.125H1.875C1.52344 2.125 1.25 2.41797 1.25 2.75V7.75C1.25 8.10156 1.52344 8.375 1.875 8.375H3.125C3.45703 8.375 3.75 8.66797 3.75 9C3.75 9.35156 3.45703 9.625 3.125 9.625H1.875ZM9.84375 4.91797C10.0391 5.09375 10.0391 5.42578 9.84375 5.58203L7.03125 8.23828C6.89453 8.375 6.69922 8.39453 6.52344 8.33594C6.34766 8.25781 6.25 8.08203 6.25 7.90625V6.5H3.75C3.39844 6.5 3.125 6.22656 3.125 5.875V4.625C3.125 4.29297 3.39844 4 3.75 4H6.25V2.59375C6.25 2.41797 6.34766 2.24219 6.52344 2.16406C6.69922 2.10547 6.89453 2.125 7.03125 2.26172L9.84375 4.91797Z'
      fill='#666666'
    />
  </svg>
)

export const Magnifier = () => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M7.56128 8.29273C5.73343 9.7567 3.05789 9.64146 1.36351 7.94707C-0.454502 6.12909 -0.454502 3.18151 1.36351 1.36351C3.18151 -0.454502 6.1291 -0.454502 7.94708 1.36351C9.64147 3.05788 9.75671 5.73342 8.29274 7.56126L11.8485 11.1169C12.0505 11.319 12.0505 11.6465 11.8485 11.8485C11.6465 12.0505 11.319 12.0505 11.117 11.8485L7.56128 8.29273ZM2.09502 7.2156C0.681012 5.80156 0.681012 3.50902 2.09502 2.09502C3.50903 0.681012 5.80157 0.681012 7.21561 2.09502C8.62855 3.50799 8.62958 5.79818 7.21871 7.21249C7.21768 7.21353 7.21665 7.21449 7.21554 7.2156C7.21451 7.21663 7.21347 7.21767 7.21251 7.2187C5.79819 8.62957 3.50799 8.62853 2.09502 7.2156Z'
      fill='#333333'
    />
  </svg>
)

export const Loading = () => (
  <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.875 2.375C11.875 3.42969 11.0156 4.25 10 4.25C8.94531 4.25 8.125 3.42969 8.125 2.375C8.125 1.35938 8.94531 0.5 10 0.5C11.0156 0.5 11.875 1.35938 11.875 2.375ZM11.875 18.625C11.875 19.6797 11.0156 20.5 10 20.5C8.94531 20.5 8.125 19.6797 8.125 18.625C8.125 17.6094 8.94531 16.75 10 16.75C11.0156 16.75 11.875 17.6094 11.875 18.625ZM0 10.5C0 9.48438 0.820312 8.625 1.875 8.625C2.89062 8.625 3.75 9.48438 3.75 10.5C3.75 11.5547 2.89062 12.375 1.875 12.375C0.820312 12.375 0 11.5547 0 10.5ZM20 10.5C20 11.5547 19.1406 12.375 18.125 12.375C17.0703 12.375 16.25 11.5547 16.25 10.5C16.25 9.48438 17.0703 8.625 18.125 8.625C19.1406 8.625 20 9.48438 20 10.5ZM2.89062 17.5703C2.1875 16.8672 2.1875 15.6562 2.89062 14.9531C3.63281 14.2109 4.84375 14.2109 5.54688 14.9531C6.28906 15.6562 6.28906 16.8672 5.54688 17.5703C4.84375 18.3125 3.63281 18.3125 2.89062 17.5703ZM5.54688 6.08594C4.84375 6.82812 3.63281 6.82812 2.89062 6.08594C2.1875 5.38281 2.1875 4.17188 2.89062 3.42969C3.63281 2.72656 4.84375 2.72656 5.54688 3.42969C6.28906 4.17188 6.28906 5.38281 5.54688 6.08594ZM14.4141 14.9531C15.1172 14.2109 16.3281 14.2109 17.0703 14.9531C17.7734 15.6562 17.7734 16.8672 17.0703 17.5703C16.3281 18.3125 15.1172 18.3125 14.4141 17.5703C13.6719 16.8672 13.6719 15.6562 14.4141 14.9531Z'
      fill='#F26B26'
    />
  </svg>
)
