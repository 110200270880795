import {useTranslations} from 'next-intl'

// components
import Image from 'next/image'

// styles
import styles from './../styles.module.scss'

interface Search {
  onClick: () => void
}

export default ({onClick}: Search) => {
  const t = useTranslations()

  return (
    <div className={styles['main-header__search']} onClick={onClick}>
      <span className={styles['main-header__search-button-icon']}>
        <Image src='/v2/Assets/Images/header/search-mobile.svg' alt='' width={16} height={16} />
      </span>
      <span>{t('MOBILE_HEADER_SEARCH')}</span>
    </div>
  )
}
