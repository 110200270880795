import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main-layout)/_components/header/_lib/components/desktop-version/categories/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main-layout)/_components/header/_lib/components/desktop-version/header-actions/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/(main-layout)/_components/header/_lib/components/desktop-version/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main-layout)/_components/header/_lib/components/desktop-version/user-settings/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main-layout)/_components/header/_lib/components/mobile-version/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[locale]/(main-layout)/_components/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CompositionRootProvider"] */ "/app/src/components/composition-root-provider/CompositionRootProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/container/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/app/src/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientComponent"] */ "/app/src/components/query-client-provider/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProviderComponent"] */ "/app/src/components/theme-provider/ThemeProvider.tsx");
