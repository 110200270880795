import {generateLocaleString} from '@/i18n'
import {useGlobalStore} from '@/store'
import Image from 'next/image'
import Link from 'next/link'
import {useShallow} from 'zustand/react/shallow'

interface Props {
  width: number
  height: number
  className?: string
  onClick?: () => void
}

const Logo = ({width, height, className, onClick}: Props) => {
  const {userSetting} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
    }))
  )

  return (
    <Link
      href={`/${generateLocaleString(userSetting.language, userSetting.country_id)}`}
      className={className}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <Image src='/v2/Assets/Images/header/logo.svg' alt='' width={width} height={height} />
    </Link>
  )
}

export default Logo
