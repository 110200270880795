'use client'

import {FAVORITE_LINK} from '../desktop-version/header-actions'
import {useTranslations} from 'next-intl'

// components
import {RenderIf} from '@/utils'
import Image from 'next/image'
import Link from 'next/link'

// styles
import styles from './styles.module.scss'

interface Props {
  isMobile?: boolean
}

const FavoriteLink = ({isMobile = false}: Props) => {
  const t = useTranslations()

  return (
    <Link href={FAVORITE_LINK} data-testid='header-favorites-page-link'>
      <div
        className={
          isMobile
            ? styles['main-header__favorite-link__mobile']
            : styles['main-header__favorite-link']
        }
      >
        <Image src='/v2/Assets/Images/header/favorite.svg' alt='' width={24} height={24} />
        <RenderIf isTrue={!isMobile}>
          <span>{t('FAVORITES')}</span>
        </RenderIf>
      </div>
    </Link>
  )
}

export default FavoriteLink
