import {isFeatureFlagEnabled} from '@/utils/featureFlags'
import * as FeatureFlagsLib from '@/utils/featureFlags/lib'

// components
import {Container} from '@/components/container/Container'
import {RenderIf} from '@/utils'
import MainHeader from './header-actions'
import Categories from './categories'
import UserSettings from './user-settings'

// styles
import styles from './styles.module.scss'

export default ({categories}: {categories: any}) => {
  const isDisplayUserSettings = isFeatureFlagEnabled(
    FeatureFlagsLib.Const.FEATURES_TYPES.DISPLAY_USER_SETTINGS
  )

  return (
    <div className={styles['desktop-header']}>
      <Container>
        <MainHeader />
      </Container>

      <hr className={styles['desktop-header__separator']} />

      <Container>
        <div className={styles['desktop-header__links']}>
          <div className={styles['desktop-header__categories']}>
            <Categories categories={categories} />
          </div>
          <RenderIf isTrue={isDisplayUserSettings}>
            <UserSettings />
          </RenderIf>
        </div>
      </Container>
    </div>
  )
}
