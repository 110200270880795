'use client'

import {CartsService} from '@/services/carts.service'
import {AnchorEl} from '@/types/Common'
import {getLargeFromMedia} from '@/utils/getLargeFromMedia'
import clsx from 'clsx'
import {useTranslations} from 'next-intl'
import {usePathname, useRouter} from 'next/navigation'
import {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import {CART_LINK} from '../desktop-version/header-actions'

// components
import UI_Button from '@/components/ui-kit/button/Button'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {RenderIf} from '@/utils'
import Popper from '@mui/material/Popper'
import Image from 'next/image'
import Link from 'next/link'

// styles
import {useGlobalStore} from '@/store'
import {handleCheckAuthStatus, handleGetUUID} from '@/utils/authHelper'

import {useShallow} from 'zustand/react/shallow'
import styles from './styles.module.scss'

interface Props {
  isMobile?: boolean
}

const shouldShowHeaderCartPopper = (pathname: string) => {
  return pathname !== '/cart'
}

export default ({isMobile = false}: Props) => {
  const {
    userSetting,
    cartsLength,
    openPopper: openPopperState,
    handleOpenPopper,
  } = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      cartsLength: state.cart.cartCount,
      openPopper: state.cart.openPopper,
      handleOpenPopper: state.handleOpenPopper,
    }))
  )

  const t = useTranslations()
  const router = useRouter()
  const pathname = usePathname()
  const {token, isLogin} = handleCheckAuthStatus()

  const cartsService = new CartsService(token, {
    country_id: userSetting.country_id,
  })

  const uuid = handleGetUUID()
  const [anchorEl, setAnchorEl] = useState<AnchorEl>(null)
  const [cartListLength, setCartListLength] = useState<number>(0)
  const [cartList, setCartList] = useState<
    {
      quantity: number
      price: string
      size: string
      name: string
      media: string
      currency: string
    }[]
  >([])
  const [isHoverOnPopper, setIsHoverOnPopper] = useState<boolean>(false)
  const [isHoverOnLink, setIsHoverOnLink] = useState<boolean>(false)

  const open = Boolean(anchorEl)
  const id = open ? 'user-settings-button' : undefined
  const showHeaderCartPopper = shouldShowHeaderCartPopper(pathname as string)
  const buttonRef = useRef(null)

  const {refetch: cartListRefetch} = useQuery<any>({
    queryFn: () => cartsService.getCartList(isLogin, uuid),
    queryKey: ['cart-list'],
    enabled: !!uuid || isLogin,
    onSuccess: (res) => {
      const {result} = res?.data
      const {products, currency} = result

      const productsArray = products?.reverse().map((product: any) => {
        const {stock, price, quantity} = product
        return {
          quantity,
          price,
          size: stock.product_color_variation.variation.contents[0]?.name,
          name: stock.store_name.concat(
            stock.product_color_variation.product_color.contents[0]?.name
          ),
          currency: currency.name,
          media: getLargeFromMedia(stock.product_color_variation.product_color.media),
        }
      })

      setCartListLength(productsArray.length)
      setCartList(productsArray.length > 2 ? productsArray.slice(0, 2) : productsArray)
    },
  })

  useEffect(() => {
    if (!!uuid || isLogin) {
      cartListRefetch()
    }
    if (openPopperState) {
      openPopper()
      window.scrollTo(0, 0)
    }
  }, [openPopperState])

  const openPopper = () => setAnchorEl(anchorEl ? null : buttonRef.current)

  const handleMouseEnter = () => {
    setIsHoverOnLink(true)
    const newAnchorEl = anchorEl ? null : buttonRef.current
    setAnchorEl(newAnchorEl)
  }

  const handleMouseLeave = () => {
    handleOpenPopper(false)
    setIsHoverOnLink(false)
  }

  useEffect(() => {
    if (!isHoverOnLink && !isHoverOnPopper) {
      handleOpenPopper(false)
      setAnchorEl(null)
    }
    if (isHoverOnLink && (!!uuid || isLogin)) {
      cartListRefetch()
    }
  }, [isHoverOnPopper, isHoverOnLink])

  return (
    <>
      <Link href={CART_LINK}>
        <div
          className={
            isMobile ? styles['main-header__cart-link__mobile'] : styles['main-header__cart-link']
          }
          ref={buttonRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <RenderIf isTrue={cartsLength > 0}>
            <div
              className={clsx('cart_length_badge', {
                [styles['cart-link__length_badge-mobile']]: isMobile,
                [styles['cart-link__length_badge']]: !isMobile,
              })}
            >
              {cartsLength}
            </div>
          </RenderIf>
          <Image src='/v2/Assets/Images/header/cart.svg' alt='' width={24} height={24} />
          <RenderIf isTrue={!isMobile}>
            <UI_Typography variant='Regular/Reg12'>{t('CART')}</UI_Typography>
          </RenderIf>
        </div>
      </Link>
      <RenderIf isTrue={cartsLength > 0 && showHeaderCartPopper}>
        <Popper
          sx={{zIndex: 10}}
          id={id}
          open={open && !isMobile}
          anchorEl={anchorEl}
          placement='bottom-end'
          disablePortal={true}
          onMouseEnter={() => setIsHoverOnPopper(true)}
          onMouseLeave={() => setIsHoverOnPopper(false)}
        >
          <div className={styles['my-cart-popper']}>
            <UI_Typography variant='Regular/Reg12'>
              {t('HEADER_CART_POPPER_MY_CART', {cartLength: cartsLength})}
            </UI_Typography>

            {cartList.map((item, index) => (
              <div key={item.name}>
                <div className={styles['cart-item']}>
                  <Image src={item.media} alt={item.name} width={50} height={76} />
                  <div className={styles['cart-item-content']}>
                    <UI_Typography variant='Regular/Reg12'>{item.name}</UI_Typography>
                    <UI_Typography variant='Regular/Reg10' color='var(--secondary-secondary)'>
                      {t('HEADER_CART_POPPER_MY_CART_CONTENT', {
                        size: item.size,
                        quantity: item.quantity,
                      })}
                    </UI_Typography>
                    <UI_Typography variant='Regular/Reg14' color='var(--primary-primary)'>
                      {`${item.price} ${item.currency}`}
                    </UI_Typography>
                  </div>
                </div>
                <RenderIf isTrue={cartList.length !== index + 1}>
                  <div className={styles['divider']} />
                </RenderIf>
              </div>
            ))}
            <RenderIf isTrue={cartListLength > 2}>
              <div className={styles['more-products']}>
                <UI_Typography
                  variant='Regular/Reg10'
                  color='var(--primary-primary)'
                  onClick={() => {
                    handleOpenPopper(false)
                    setAnchorEl(null)
                    router.push('/cart')
                  }}
                >
                  {t('HEADER_CART_POPPER_MY_CART_2_MORE_PRODUCTS', {
                    cartListLength: cartListLength - 2,
                  })}
                </UI_Typography>
              </div>
            </RenderIf>
            <div className={styles['buttons']}>
              <UI_Button
                variant='outline-primary'
                onClick={() => {
                  handleOpenPopper(false)
                  setAnchorEl(null)
                  router.push('/cart')
                }}
              >
                <UI_Typography variant='Regular/Reg12' color='var(--primary-primary)'>
                  {t('HEADER_CART_POPPER_MY_CART_GO_TO_CART')}
                </UI_Typography>
              </UI_Button>
              <UI_Button
                variant='primary'
                onClick={() => {
                  handleOpenPopper(false)
                  setAnchorEl(null)
                  if (isLogin) {
                    router.push('/payment')
                  } else {
                    router.push('/auth/login?redirect_url=/payment')
                  }
                }}
              >
                <UI_Typography variant='Medium/Med12' color='var(--basic-white)'>
                  {t('HEADER_CART_POPPER_MY_CART_COMPLETE_ORDER')}
                </UI_Typography>
              </UI_Button>
            </div>
          </div>
        </Popper>
      </RenderIf>
    </>
  )
}
