'use client'

import {useEffect, useMemo, useState} from 'react'
import {useTranslations} from 'next-intl'

// components
import {RenderIf} from '@/utils'
import Image from 'next/image'
import Link from 'next/link'

// styles
import styles from './styles.module.scss'
import {handleCheckAuthStatus} from '@/utils/authHelper'

interface Props {
  isMobile?: boolean
}

export default ({isMobile = false}: Props) => {
  const t = useTranslations()
  const {isLogin} = handleCheckAuthStatus()
  const href = useMemo(() => (isLogin ? '/my-account' : '/auth/login'), [isLogin])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  if (!isLoaded) return null

  return (
    <Link href={href} data-testid='account-page-link'>
      <div
        className={
          isMobile
            ? styles['main-header__user-profile-link__mobile']
            : styles['main-header__user-profile-link']
        }
      >
        <Image src='/v2/Assets/Images/header/user-profile.svg' alt='' width={24} height={24} />
        <RenderIf isTrue={!isMobile}>
          <span>{isLogin ? t('LOGGED_IN_PROFILE') : t('PROFILE')}</span>
        </RenderIf>
      </div>
    </Link>
  )
}
