'use client'

import {memo} from 'react'
import {UserSettingModal} from '@/components/user-setting-modal/UserSettingModal'
import {getCountryByCountryId} from '@/i18n'
import {MenuItem} from '@/services/menus.service'
import {useMobileSidebar} from './_lib/hooks'
import clsx from 'clsx'
import styles from './styles.module.scss'
import * as Lib from './_lib'

// components
import {RenderIf} from '@/utils'
import Link from 'next/link'
import Image from 'next/image'
import Logo from '../logo'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import SearchSuggestion from '@/components/search-suggestion'

const MobileSidebar = memo(
  ({
    isOpen,
    searchMode,
    primaryMenu,
    secondaryMenu,
    handleClose,
  }: {
    isOpen: boolean
    searchMode: boolean
    primaryMenu: MenuItem[]
    secondaryMenu: MenuItem[]
    handleClose: () => void
  }) => {
    const {get, set, on} = useMobileSidebar({
      isOpen,
      searchMode,
      primaryMenu,
      secondaryMenu,
      handleClose,
    })

    return (
      <>
        <div
          className={clsx(styles['wrapper'], isOpen && styles['wrapper--open'])}
          id='mobile-menu-wrapper'
        >
          {/* header */}
          <div className={clsx(styles['wrapper__header'])}>
            <Logo
              className={styles['wrapper__header__logo']}
              width={51}
              height={16}
              onClick={handleClose}
            />

            <div className={styles['wrapper__header__search']}>
              <button type='button' onClick={on.handleClickSearchIcon}>
                <Lib.I.Search fill={get.enableSearchMode ? '#f26b26' : undefined} />
              </button>
            </div>
            <button
              onClick={handleClose}
              className={styles['wrapper__header__close']}
              data-testid='mobile-sidebar-close-button'
            >
              <Lib.I.Close />
            </button>
          </div>

          {/* body */}
          <div className={styles['wrapper__body']}>
            {/* search */}
            <RenderIf isTrue={get.enableSearchMode && get.isRender}>
              {/* search input */}
              <div className={styles['wrapper__body__search-input']}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault()
                  }}
                >
                  <Lib.I.Search fill='#333333' />
                  <input
                    type='text'
                    ref={get.searchInputRef}
                    value={get.searchInputValue}
                    placeholder={get.t('MOBILE_SIDEBAR_SEARCH_INPUT_PLACEHOLDER')}
                    onChange={(e) => {
                      on.handleSubmitSearch(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') on.handleNavigateToSearch()
                    }}
                    data-testid='mobile-sidebar-search-input'
                  />
                </form>
              </div>

              {/* search result */}
              <SearchSuggestion
                items={get.searchResult}
                isLoading={get.isFetching}
                searchTerm={get.searchInputValue}
                isMobile={true}
                handleClickItem={on.handleClickOnSearchItem}
              />
            </RenderIf>

            {/* content tabs */}
            <RenderIf isTrue={!get.enableSearchMode && get.isRender}>
              <div className={styles['wrapper__body__tabs-buttons']}>
                <button
                  type='button'
                  data-testid='mobile-menu-tabs-button'
                  className={get.activeTab === 'CATEGORIES' ? styles['active'] : ''}
                  onClick={() => {
                    set.setActiveTab('CATEGORIES')
                  }}
                >
                  <UI_Typography variant='Regular/Reg14'>
                    {get.t('SIDEBAR_CATEGORIES')}
                  </UI_Typography>
                </button>
                <button
                  type='button'
                  data-testid='mobile-menu-tabs-button'
                  className={get.activeTab === 'HIGHLIGHTS' ? styles['active'] : ''}
                  onClick={() => {
                    set.setActiveTab('HIGHLIGHTS')
                  }}
                >
                  <UI_Typography variant='Regular/Reg14'>{get.t('HIGHLIGHTS')}</UI_Typography>
                </button>
              </div>
              {/* tabs body */}
              <div className={styles['wrapper__body__tabs-body']}>
                {on.handleRenderTabBody(get.activeTab)}
              </div>
            </RenderIf>

            <RenderIf isTrue={!get.enableSearchMode}>
              {/* auth buttons */}
              <div
                className={clsx(
                  styles['wrapper__body__auth-section'],
                  get.isLogin ? styles['wrapper__body__auth-section--logged-in'] : ''
                )}
              >
                {/* title and welcome */}
                <UI_Typography
                  component={'p'}
                  variant='Medium/Med14'
                  className={styles['wrapper__body__auth-section__title']}
                >
                  {get.t('SIDEBAR_PROFILE')}
                </UI_Typography>

                {/* profile actions */}
                <RenderIf isTrue={get.isLogin && get.isRender}>
                  <>
                    <UI_Typography
                      component={'p'}
                      variant='Regular/Reg12'
                      className={styles['wrapper__body__auth-section__welcome']}
                    >
                      {get.t('MY_ACCOUNT_GREET') +
                        (get.userInfo && (get.userInfo.name || get.userInfo.surname) ? ',' : '')}
                      <UI_Typography variant='Medium/Med12'>
                        {`${get.userInfo?.name || ''} ${get.userInfo?.surname || ''}`.trim()}
                      </UI_Typography>
                    </UI_Typography>

                    {/* profile buttons */}
                    <div className={styles['wrapper__body__auth-section__profile-actions']}>
                      <Link
                        className={clsx(
                          styles['wrapper__body__auth-section__profile-actions__button']
                        )}
                        href={'/my-account'}
                      >
                        <UI_Typography variant='Regular/Reg14'>
                          {get.t('LOGGED_IN_PROFILE')}
                        </UI_Typography>
                      </Link>
                      <Link
                        className={styles['wrapper__body__auth-section__profile-actions__button']}
                        href={'/my-account/orders'}
                      >
                        <UI_Typography variant='Regular/Reg14'>{get.t('MY_ORDERS')}</UI_Typography>
                      </Link>
                      <Link
                        className={styles['wrapper__body__auth-section__profile-actions__button']}
                        href={'/favorite'}
                      >
                        <UI_Typography variant='Regular/Reg14'>{get.t('FAVORITES')}</UI_Typography>
                      </Link>
                    </div>
                  </>
                </RenderIf>

                {/* auth actions */}
                <RenderIf isTrue={!get.isLogin && get.isRender}>
                  <>
                    <div className={styles['wrapper__body__auth-section__auth-actions']}>
                      <Link
                        className={clsx(
                          styles['wrapper__body__auth-section__auth-actions__button'],
                          styles['wrapper__body__auth-section__auth-actions__button--active']
                        )}
                        href={'/auth/login'}
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        <UI_Typography variant='Regular/Reg14'>{get.t('LOGIN')}</UI_Typography>
                      </Link>
                      <Link
                        className={styles['wrapper__body__auth-section__auth-actions__button']}
                        href={'/auth/register'}
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        <UI_Typography variant='Regular/Reg14'>{get.t('SIGN_UP')}</UI_Typography>
                      </Link>
                    </div>

                    {/* auth message */}
                    <UI_Typography
                      variant='Regular/Reg10'
                      className={styles['wrapper__body__auth-section__message']}
                    >
                      {get.t('SIDEBAR_AUTH_MESSAGE')}
                    </UI_Typography>
                  </>
                </RenderIf>
              </div>

              {/* language */}
              <div className={styles['wrapper__body__language-section']}>
                <UI_Typography
                  className={styles['wrapper__body__language-section__title']}
                  variant='Medium/Med14'
                >
                  {get.t('SIDEBAR_REGION_AND_LANGUAGE')}
                </UI_Typography>
                <div
                  className={styles['wrapper__body__language-section__button']}
                  onClick={() => {
                    on.handleToggleUserSettingModal(true)
                  }}
                >
                  <div>
                    <Image
                      className={styles['wrapper__body__language-section__flag']}
                      src={
                        getCountryByCountryId(get.userSetting.country_id.toString())?.iso2 === 'DE'
                          ? '/v2/Assets/Icons/Germany.svg'
                          : '/v2/Assets/Icons/Turkey.svg'
                      }
                      alt='country flag'
                      width={17}
                      height={17}
                    />

                    {get.isRender && (
                      <UI_Typography variant='Regular/Reg12'>
                        {get.userSetting.country_name}
                      </UI_Typography>
                    )}
                  </div>
                  <UI_Typography
                    variant='Medium/Med12'
                    className={styles['wrapper__body__language-section__message']}
                  >
                    {get.t('CHANGE_AND_EDIT')}
                  </UI_Typography>
                </div>
              </div>
            </RenderIf>

            {/* signout */}
            <RenderIf isTrue={get.isLogin && get.isRender && !get.enableSearchMode}>
              <button
                className={styles['wrapper__body__signout']}
                type='button'
                onClick={on.handleSignOut}
              >
                <Lib.I.SignOut />
                <UI_Typography variant='Regular/Reg12'>{get.t('SIGNOUT')}</UI_Typography>
              </button>
            </RenderIf>
          </div>
        </div>
        <UserSettingModal router='appRouter' />
      </>
    )
  }
)

export default MobileSidebar
