import useCustomMediaQuery from '@/component-v2/core/IsLessThanMid'
import {setLanguage as pagesRouterSetLanguage} from '@/i18n/i18n'
import {setLanguage as appRouterSetLanguage} from '@/i18n'
import {UserSettingsService} from '@/services/user-settings.service'
import {UserSettings} from '@/types/UserSettings'
import {useMutation, useQuery} from 'react-query'
import {setClientSideCookie} from '@/utils'
import {useGlobalStore} from '@/store'
import {useShallow} from 'zustand/react/shallow'
import {handleCheckAuthStatus, handleGetUUID, handleSetUUID} from '@/utils/authHelper'
import {useState} from 'react'

export const useUserSettingModal = ({router}: {router: 'appRouter' | 'pagesRouter'}) => {
  const {userSetting, handleToggleUserSettingModal, handleUpdateUserSetting} = useGlobalStore(
    useShallow((state) => ({
      userSetting: state.userSetting,
      handleToggleUserSettingModal: state.handleToggleUserSettingModal,
      handleUpdateUserSetting: state.handleUpdateUserSetting,
    }))
  )
  const isMobile = useCustomMediaQuery(576)
  const {token, isLogin} = handleCheckAuthStatus()

  const [temporarySelectedCountry, setTemporarySelectedCountry] = useState<{
    country_id: number
    language: string
    country_name: string
    getUserSettingLoading: boolean
    currencyName: string
    countryFlagUrl: string
    phoneCode: number
    iso3: string
  } | null>(null)

  // Service
  const userSettingsService = new UserSettingsService(token, {
    country_id: userSetting.country_id,
  })

  const {data} = useQuery({
    queryFn: () => userSettingsService.getCountriesList(),
    queryKey: ['countries'],
  })

  const {mutate: addToUserSettingsMutation} = useMutation(
    ({data}: {data: UserSettings}) => userSettingsService.addToUserSettings(data),
    {
      onSuccess: (res) => {
        const {result: setting} = res.data

        setClientSideCookie('countryId', setting.country.id.toString())
        setClientSideCookie('language', setting.language)

        handleUpdateUserSetting({
          country_id: setting.country_id,
          country_name: setting.country.name,
          countryFlagUrl: setting.country.flag_url,
          currencyName: setting.currency.name,
          getUserSettingLoading: false,
          iso3: userSetting.iso3,
          language: setting.language,
          phoneCode: +setting.country.phone_code,
        })

        router === 'pagesRouter'
          ? pagesRouterSetLanguage(setting.language, setting.country.id.toString())
          : appRouterSetLanguage(setting.language, setting.country.id.toString(), true)
      },
    }
  )

  const {mutate: addToUserSettingsAnonymousMutation} = useMutation(
    ({data, uuid}: {data: UserSettings; uuid?: string}) =>
      userSettingsService.addToUserSettingsAnonymous(data, uuid),
    {
      onSuccess: (res) => {
        const {uuid, setting} = res?.data.result
        handleSetUUID(uuid)

        setClientSideCookie('countryId', setting.country.id.toString())
        setClientSideCookie('language', setting.language)

        handleUpdateUserSetting({
          country_id: setting.country_id,
          country_name: setting.country.name,
          countryFlagUrl: setting.country.flag_url,
          currencyName: setting.currency.name,
          getUserSettingLoading: false,
          iso3: userSetting.iso3,
          language: setting.language,
          phoneCode: +setting.country.phone_code,
        })

        router === 'pagesRouter'
          ? pagesRouterSetLanguage(setting.language, setting.country.id.toString())
          : appRouterSetLanguage(setting.language, setting.country.id.toString(), true)
      },
    }
  )

  const handleSaveUserSettings = () => {
    if (isLogin) {
      addToUserSettingsMutation({
        data: {
          country_id: temporarySelectedCountry?.country_id.toString(),
          language: temporarySelectedCountry?.language,
        },
      })
    } else {
      const uuid = handleGetUUID()

      addToUserSettingsAnonymousMutation({
        data: {
          country_id: temporarySelectedCountry?.country_id.toString(),
          language: temporarySelectedCountry?.language,
        },
        uuid: uuid ? uuid : undefined,
      })
    }

    handleToggleUserSettingModal(false)
  }

  const handleToggleModal = () => {
    handleToggleUserSettingModal(!userSetting.showUserSettingModal)
  }

  return {
    get: {
      countries: data?.data.result,
      show: userSetting.showUserSettingModal,
      isMobile,
      temporarySelectedCountry,
    },
    on: {handleSaveUserSettings, handleToggleModal, setTemporarySelectedCountry},
  }
}
