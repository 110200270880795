import {useGetCurrentLanguage, useTranslate} from '@/hooks/useTranslate'
import {MenuItem} from '@/services/menus.service'
import {useGlobalStore} from '@/store'
import {
  handleCheckAuthStatus,
  handleGetAuthCookie,
  handleRemoveAuthCookie,
} from '@/utils/authHelper'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useShallow} from 'zustand/react/shallow'
import * as C from './components/index'
import {useQuery} from 'react-query'
import {ProductsSearchService} from '@/services/products-search.service'
import {SearchSuggestionItem} from '@/services/lib/types/productSearch.type'
import * as Sentry from '@sentry/nextjs'
import {apiRoutes} from '@/utils/apiRoutes'
import {useRouter} from 'next/navigation'
import {
  lastSearchScrollStateKey,
  lastSearchStateKey,
} from '@/app/[locale]/(main-layout)/categories/[cid]/_lib/constants'

type Tabs = 'CATEGORIES' | 'HIGHLIGHTS'
const SEACH_BOUNCE_TIME = 500

export const useMobileSidebar = ({
  isOpen,
  searchMode,
  primaryMenu,
  secondaryMenu,
  handleClose,
}: {
  isOpen: boolean
  searchMode: boolean
  primaryMenu: MenuItem[]
  secondaryMenu: MenuItem[]
  handleClose: () => void
}) => {
  const t = useTranslate
  const {userSetting, handleToggleUserSettingModal, handleUpdateCart, handleUpdateSearchText} =
    useGlobalStore(
      useShallow((state) => ({
        userSetting: state.userSetting,
        handleToggleUserSettingModal: state.handleToggleUserSettingModal,
        handleUpdateCart: state.handleUpdateCart,
        handleUpdateSearchText: state.handleUpdateSearchText,
      }))
    )
  const userInfo = handleGetAuthCookie()
  const language = useGetCurrentLanguage()
  const router = useRouter()

  const [activeTab, setActiveTab] = useState<Tabs>('CATEGORIES')
  const [enableSearchMode, setEnableSearchMode] = useState(false)
  const [searchInputValue, setSearchInputValue] = useState('')
  const {isLogin} = handleCheckAuthStatus()
  const [searchResult, setSearchResult] = useState<null | SearchSuggestionItem[]>(null)
  const [isRender, setIsRender] = useState(false)
  const searchTimeoutId = useRef<NodeJS.Timeout | null>(null)
  const searchInputRef = useRef(null)

  /**
   * handle sign out
   */
  const handleSignOut = () => {
    handleRemoveAuthCookie()

    handleUpdateCart(0)

    handleClose()
    window.location.href = '/v2'
  }

  /**
   * Renders the tab body based on the active tab.
   * @param {Tabs} activeTab - The currently active tab ('CATEGORIES' or 'HIGHLIGHTS').
   * @returns {JSX.Element[]} An array of JSX elements representing the banners for the active tab.
   */
  const handleRenderTabBody = (activeTab: Tabs) => {
    let items: any[] = []
    switch (activeTab) {
      case 'CATEGORIES': {
        items = primaryMenu && primaryMenu.length ? primaryMenu : []
        break
      }
      case 'HIGHLIGHTS': {
        items = secondaryMenu && secondaryMenu.length ? secondaryMenu : []
        break
      }
      default: {
        break
      }
    }

    return items.map((c: any) => {
      return (
        <C.Banner
          key={c.name}
          url={c.link}
          title={c.name}
          onClick={handleClose}
          imageURL={c.imageUrl}
        />
      )
    })
  }

  /**
   * Removes the last search state from session storage.
   * This function removes the last search state and scroll state from session storage.
   */
  const handleRemoveLastSearchState = () => {
    sessionStorage.removeItem(lastSearchStateKey)
    sessionStorage.removeItem(lastSearchScrollStateKey)
  }

  /**
   * Handles the submission of a search query.
   *
   * @param {string} value - The search query value.
   * @description This function manages the search input value and triggers a search after a delay.
   * It clears any existing timeout, updates the search input value, and sets a new timeout to
   * perform the search after some milliseconds.
   */
  const handleSubmitSearch = (value: string) => {
    if (searchTimeoutId.current) clearTimeout(searchTimeoutId.current)

    setSearchInputValue(value)

    searchTimeoutId.current = setTimeout(() => {
      handleSearch()
    }, SEACH_BOUNCE_TIME)
  }

  /**
   * Toggles the search mode and resets search-related states.
   */
  const handleClickSearchIcon = () => {
    if (!enableSearchMode) {
      // @ts-ignore
      searchInputRef.current?.focus()
    }
    setEnableSearchMode(!enableSearchMode)
    setSearchInputValue('')
    setSearchResult(null)
  }

  /**
   * Handles navigation to the search results page.
   * If a search input value exists, it updates the stored search text
   * and navigates to the search results page with the query parameter.
   */
  const handleNavigateToSearch = () => {
    handleRemoveLastSearchState()
    if (!!searchInputValue) {
      handleClickOnSearchItem()

      router.push(`/search${apiRoutes.PRODUCT_LIST}?q=${searchInputValue}`)
    }
  }

  /**
   * Handles the click event on a search item.
   * Closes the search, then clears the search input and results after a short delay.
   */
  const handleClickOnSearchItem = () => {
    handleUpdateSearchText(searchInputValue)
    handleClose()
    handleRemoveLastSearchState()

    setTimeout(() => {
      setSearchInputValue('')
      setSearchResult(null)
    }, 300)
  }

  const productSearchService = useMemo(() => {
    return new ProductsSearchService('', {
      country_id: userSetting.country_id,
    })
  }, [userSetting.country_id])

  const {refetch: handleSearch, isFetching} = useQuery({
    queryFn: async () => {
      if (!searchInputValue) {
        setSearchResult(null)

        return
      }

      const response = await productSearchService.searchSuggestions(language, searchInputValue)

      setSearchResult(response.data.result)

      return
    },
    enabled: false,
    onError: (error) => {
      Sentry.captureException(error)
    },
  })

  useEffect(() => {
    setIsRender(true)
  }, [])

  /**
   * manages document overflow and search mode based on menu open state.
   * scrolls menu to top and resets search mode when closed.
   */
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden'
      if (searchMode) {
        setEnableSearchMode(true)
        // @ts-ignore
        if (searchInputRef.current) searchInputRef.current.focus()
      }
    } else {
      document.documentElement.style.overflow = 'unset'
      const menuWrapper = document.getElementById('mobile-menu-wrapper')
      if (menuWrapper) {
        setTimeout(() => {
          menuWrapper.scrollTo({top: 0, behavior: 'smooth'})
          setEnableSearchMode(false)
          setSearchResult(null)
          setSearchInputValue('')
        }, 310)
      }
    }
  }, [isOpen])

  useEffect(() => {
    if (typeof window !== 'undefined' && isOpen && searchMode && searchInputRef.current) {
      if (searchInputValue) {
        const span = document.createElement('span')
        span.style.visibility = 'hidden'
        span.style.whiteSpace = 'pre'
        span.style.position = 'absolute'
        span.textContent =
          (searchInputRef.current as unknown as HTMLInputElement).value ||
          (searchInputRef.current as unknown as HTMLInputElement).placeholder
        document.body.appendChild(span)
        ;(searchInputRef.current as unknown as HTMLInputElement).style.width = `${
          span.offsetWidth + 4
        }px`

        document.body.removeChild(span)
      } else {
        ;(searchInputRef.current as unknown as HTMLInputElement).style.removeProperty('width')
      }
    }
  }, [searchInputValue])

  useEffect(() => {
    if (enableSearchMode) {
      setTimeout(() => {
        ;(searchInputRef.current as unknown as HTMLInputElement).focus()
      }, 100)
    }
  }, [enableSearchMode])

  return {
    get: {
      isRender,
      isFetching,
      activeTab,
      enableSearchMode,
      isLogin,
      userInfo,
      searchResult,
      searchInputRef,
      searchInputValue,
      userSetting,
      t,
    },
    set: {
      setEnableSearchMode,
      setSearchResult,
      setSearchInputValue,
      setActiveTab,
    },
    on: {
      handleToggleUserSettingModal,
      handleSignOut,
      handleRenderTabBody,
      handleSubmitSearch,
      handleClickOnSearchItem,
      handleClickSearchIcon,
      handleNavigateToSearch,
    },
  }
}
