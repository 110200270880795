'use client'

import {useTranslations} from 'next-intl'
import clsx from 'clsx'

// components
import Image from 'next/image'

// styles
import styles from './styles.module.scss'

interface Props {
  onClick: () => void
}

export default ({onClick}: Props) => {
  const t = useTranslations()

  return (
    <div
      className={clsx('main-header__menu-button', styles['main-header__menu-button'])}
      onClick={onClick}
    >
      <Image src='/v2/Assets/Images/header/menu.svg' alt='' width={24} height={24} />
      <span>{t('MENU')}</span>
    </div>
  )
}
