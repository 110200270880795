import {memo, ReactNode} from 'react'
import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import styles from './styles.module.scss'
import Link from 'next/link'

interface Props {
  title: string
  secondTitle?: string
  imageURL?: string
  badges?: ReactNode
  url: string
  onClick: () => void
}

const Banner = memo(({title, secondTitle, imageURL, badges, url, onClick}: Props) => {
  return (
    <Link className={styles['banner']} href={url} onClick={onClick}>
      <div className={styles['banner__content']}>
        <UI_Typography
          component={'p'}
          variant='Regular/Reg14'
          className={styles['banner__content__title']}
        >
          {title}
        </UI_Typography>
        <UI_Typography
          component={'p'}
          variant='Regular/Reg14'
          className={styles['banner__content__title']}
        >
          {secondTitle}
        </UI_Typography>
        <div className={styles['banner__content__badges']}>{badges}</div>
      </div>
      <div
        className={styles['banner__image']}
        style={{
          backgroundImage: imageURL ? `url('${imageURL}')` : '',
        }}
      ></div>
    </Link>
  )
})

export default Banner
